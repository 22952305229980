import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import Nav from '../components/nav'
import Img from "gatsby-image"
import Footer from '../components/footer'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <Nav />
            <div className="layout">
                <div className="layout__wide body padding__large">
                    <div className="flex flex__space-between">
                        <div className="col__45 mobile__padding__small--bottom">
                            <h1>{ entry.frontmatter.heading }</h1>
                        </div>
                        <div className="col__45">
                            <div dangerouslySetInnerHTML={{ __html: entry.fields.intro_text }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout">
                <figure>
                    <Img fluid={entry.frontmatter.image.childImageSharp.fluid} alt={entry.frontmatter.heading} />
                </figure>
            </div>
            <div className="layout">
                <div className="layout__wide padding__large body">
                    <div className="flex flex__space-between">
                        <div className="col__45">
                            &nbsp;
                        </div>
                        <div className="col__45">
                            <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                            <Link to="/contact/" className="btn btn--fill">Request Quote &#9658;</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
				meta_description
                heading
                sub_heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
                slug
                intro_text
			}
			html
		}
	}
`